<template>
  <tr
    :key="item.id"
    :class="{
      'v-data-table__mobile-table-row': !$vuetify.breakpoint.mdAndUp,
    }"
    class="table-row"
    @click.stop="clickRow(item)"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__checkbox-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="pt-2 text-center"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      ></div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <v-simple-checkbox
          :ripple="false"
          :value="isSelected"
          color="primary"
          @input="selectOnChange($event)"
        />
      </div>
    </td>
    <td
      class="pt-2 text-center"
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__5-row': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        <div>
          {{ $trans("id") }}
        </div>
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="text-left">
          <div>{{ item.id }}</div>
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__15-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("created_at") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <span>{{
          item.created_at | dateTime($helpers.hourDayMonthAndYearDate)
        }}</span>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__15-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("canceled_at") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <span v-if="item.canceled_at">{{
          item.canceled_at | dateTime($helpers.hourDayMonthAndYearDate)
        }}</span>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__25-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("subscription") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div v-if="item.subscription">
          <span
            >(ID: {{ item.subscription.id }}) {{ item.subscription.name }}</span
          ><br />
          <span>{{ item.stripe_subscription_id }}</span>
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__20-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("client") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="d-flex align-center flex-wrap">
          <user-card-details
            dense
            details-in-tooltip
            justify-left
            clickable
            push-transition
            :user="item.user"
          />
          <gdpr-fields-tooltip
            v-if="item.gdpr_fields"
            class="mt-2"
            :gdpr-fields="item.gdpr_fields"
          />
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__5-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("status") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="text-center">
          <v-chip
            small
            :color="$helpers.getStatusColor(item.status).color"
            :class="$helpers.getStatusColor(item.status).class"
            class="ma-2"
          >
            {{ $trans(item.status) }}
          </v-chip>
          <small v-if="item.ends_at" class="d-block">
            {{ $trans("ends_at") }}:
            {{ item.ends_at | dateTime($helpers.hourDayMonthAndYearDate) }}
          </small>
          <small
            v-if="item.status === 'canceled' && !item.ends_at"
            class="d-block"
          >
            {{ $trans("ends_at_default") }}
          </small>
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__5-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("usage") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="text-center">
          <v-chip
            v-if="canShowUsage(item)"
            text
            small
            color="green-light"
            class="green--text ma-2"
          >
            <span>{{ getUsage(item) }}</span>
          </v-chip>
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__manage-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div class="row-list-buttons white rounded mr-1 mt-2">
        <user-subscription-list-buttons
          :is-hovered="isHovered"
          :user-subscription="item"
        />
      </div>
    </td>
  </tr>
</template>
<script>
import UserCardDetails from "@/components/UserCardDetails.vue";
import UserSubscriptionListButtons from "@/views/dashboard/pages/Sales/UserSubscriptions/components/UserSubscriptionListButtons.vue";
import GdprFieldsTooltip from "@/components/GdprFieldsTooltip.vue";
import itemRowActions from "@/calendesk/mixins/itemRowActions";

export default {
  components: {
    GdprFieldsTooltip,
    UserSubscriptionListButtons,
    UserCardDetails,
  },
  mixins: [itemRowActions],
  methods: {
    canShowUsage(userSubscription) {
      if (userSubscription.status === "active") {
        return true;
      }

      if (userSubscription.status === "canceled") {
        return userSubscription.ends_at
          ? this.$moment(userSubscription.ends_at).isAfter(this.$moment())
          : true;
      }

      return false;
    },
    getUsage(userSubscription) {
      if (userSubscription.subscription.service_limit) {
        return `${userSubscription.current_period_usage}/${userSubscription.subscription.service_limit}`;
      }

      return `${userSubscription.current_period_usage}/∞`;
    },
  },
};
</script>
