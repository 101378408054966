<template>
  <v-menu
    ref="datepickerMenu"
    v-model="datepickerMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    :max-width="$vuetify.breakpoint.mdAndUp ? '450px' : '290px'"
    :min-width="$vuetify.breakpoint.mdAndUp ? '450px' : '290px'"
  >
    <template #activator="{ on }">
      <v-text-field
        :value="dateRangeText"
        outlined
        :label="label"
        persistent-hint
        append-icon="$calendar"
        :disabled="disabled"
        readonly
        :rules="rules"
        hide-details="auto"
        :dense="dense"
        :clearable="clearable"
        :class="additionalClass"
        @click:append="datepickerMenu = true"
        @click:clear="$emit('input', null)"
        v-on="on"
      />
    </template>
    <v-row no-gutters class="white">
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 4 : 6">
        <v-list dense>
          <v-list-item @click="setDateRange('today')">
            <v-list-item-title>{{ $trans("today") }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!onlyPastDates" @click="setDateRange('tomorrow')">
            <v-list-item-title>{{ $trans("tomorrow") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="setDateRange('thisWeek')">
            <v-list-item-title>{{ $trans("this_week") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="setDateRange('thisMonth')">
            <v-list-item-title>{{ $trans("this_month") }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="onlyPastDates" @click="setDateRange('thisYear')">
            <v-list-item-title>{{ $trans("this_year") }}</v-list-item-title>
          </v-list-item>
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-divider></v-divider>
            <v-list-item @click="setDateRange('yesterday')">
              <v-list-item-title>{{ $trans("yesterday") }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="setDateRange('lastWeek')">
              <v-list-item-title>{{ $trans("last_week") }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="setDateRange('lastMonth')">
              <v-list-item-title>{{ $trans("last_month") }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="setDateRange('lastYear')">
              <v-list-item-title>{{ $trans("last_year") }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-col>
      <v-col v-if="!$vuetify.breakpoint.mdAndUp" cols="6">
        <v-list dense>
          <v-divider></v-divider>
          <v-list-item @click="setDateRange('yesterday')">
            <v-list-item-title>{{ $trans("yesterday") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="setDateRange('lastWeek')">
            <v-list-item-title>{{ $trans("last_week") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="setDateRange('lastMonth')">
            <v-list-item-title>{{ $trans("last_month") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="setDateRange('lastYear')">
            <v-list-item-title>{{ $trans("last_year") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 8 : 12">
        <v-date-picker
          ref="datePicker"
          v-model="dates"
          first-day-of-week="1"
          no-title
          :min="minDate"
          range
          @input="$emit('input', dates)"
        >
          <v-btn
            v-if="dates && dates.length > 0"
            color="error"
            text
            @click="cleanDates"
          >
            {{ $trans("clear") }}
          </v-btn>
          <v-spacer />
          <v-btn text color="primary" @click="$refs.datepickerMenu.save(dates)">
            {{ $trans("ok") }}
          </v-btn>
        </v-date-picker>
      </v-col>
    </v-row>
  </v-menu>
</template>

<script>
export default {
  name: "CalendarRangeInput",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    onlyPastDates: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Array, Object],
      default: () => [],
    },
    additionalClass: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      datepickerMenu: false,
      dates: this.value,
    };
  },
  computed: {
    dateRangeText() {
      return this.dates ? this.dates.join(" - ") : "";
    },
    minDate() {
      if (this.dates && this.dates.length === 1) {
        return this.dates[0];
      }

      return null;
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.dates = val;
      } else {
        this.dates = null;
      }
    },
    dates(val) {
      if (val && val.length === 2 && new Date(val[0]) > new Date(val[1])) {
        this.dates = [val[0], val[0]];
        this.$emit("input", this.dates);
      }
    },
  },
  methods: {
    setDateRange(range) {
      let startDate, endDate;
      switch (range) {
        case "today":
          startDate = endDate = this.$moment().format("YYYY-MM-DD");
          break;
        case "tomorrow":
          startDate = this.$moment()
            .startOf("day")
            .add(1, "days")
            .format("YYYY-MM-DD");
          endDate = this.$moment()
            .endOf("day")
            .add(1, "days")
            .format("YYYY-MM-DD");
          break;
        case "thisWeek":
          startDate = this.$moment().startOf("week").format("YYYY-MM-DD");
          endDate = this.$moment().endOf("week").format("YYYY-MM-DD");
          break;
        case "thisMonth":
          startDate = this.$moment().startOf("month").format("YYYY-MM-DD");
          endDate = this.$moment().endOf("month").format("YYYY-MM-DD");
          break;
        case "thisYear":
          startDate = this.$moment().startOf("year").format("YYYY-MM-DD");
          endDate = this.$moment().endOf("year").format("YYYY-MM-DD");
          break;
        case "yesterday":
          startDate = endDate = this.$moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD");
          break;
        case "lastWeek":
          startDate = this.$moment()
            .subtract(1, "week")
            .startOf("week")
            .format("YYYY-MM-DD");
          endDate = this.$moment()
            .subtract(1, "week")
            .endOf("week")
            .format("YYYY-MM-DD");
          break;
        case "lastMonth":
          startDate = this.$moment()
            .subtract(1, "month")
            .startOf("month")
            .format("YYYY-MM-DD");
          endDate = this.$moment()
            .subtract(1, "month")
            .endOf("month")
            .format("YYYY-MM-DD");
          break;
        case "lastYear":
          startDate = this.$moment()
            .subtract(1, "year")
            .startOf("year")
            .format("YYYY-MM-DD");
          endDate = this.$moment()
            .subtract(1, "year")
            .endOf("year")
            .format("YYYY-MM-DD");
          break;
      }
      this.dates = [startDate, endDate];

      if (this.$refs.datePicker) {
        this.$refs.datePicker.tableDate = startDate;
        this.$emit("input", this.dates);
        this.datepickerMenu = false;
      }
    },
    cleanDates() {
      this.dates = [];
      this.datepickerMenu = false;
      this.$emit("input", this.dates);
    },
  },
};
</script>
