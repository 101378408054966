<template>
  <div v-if="!isSending" class="list mb-16">
    <user-subscriptions-filter
      v-if="userSubscriptionsExist"
      @filtersChanged="filtersChanged"
      @filtersRestored="filtersRestored"
    />
    <no-data-info
      v-else-if="!userSubscriptionsExist"
      :description="$trans('no_user_subscriptions_info')"
      :button-text="$trans('create_user_subscription_button_title')"
      @click="openAddUserSubscription"
    />
    <v-data-table
      v-if="userSubscriptionsExist"
      v-model="selected"
      :footer-props="footerProps"
      :headers="headers"
      :items="items"
      :loading="isLoadingTable"
      :loading-text="$trans('loading')"
      :no-data-text="$trans('nothing_found_here')"
      :no-results-text="$trans('nothing_found_here')"
      :options.sync="tableOptions"
      :server-items-length="total"
      :show-select="!noData"
      class="calendesk-datatable"
      mobile-breakpoint="960"
    >
      <template #[`header.data-table-select`]>
        <div class="text-center">
          <v-simple-checkbox
            v-model="selectAllState"
            :ripple="false"
            :indeterminate="isIndeterminateForSelectAll"
            color="primary"
            @input="selectAllItemsOnChange"
          />
        </div>
      </template>

      <template #[`item`]="{ item }">
        <user-subscription-row
          :item="item"
          :is-selected="
            isRowSelected({
              id: item.id,
            })
          "
          @selectOnChange="itemRowEventOnSelectChange"
        />
      </template>
    </v-data-table>
    <table-footer-menu
      :on-select-actions="onSelectActions"
      :selected="selected"
      :is-loading-in-select-menu="isLoadingInSelectMenu"
      :selection-map="
        (rowItem) => {
          return {
            id: rowItem.item.id,
            status: rowItem.item.status,
          };
        }
      "
    />
  </div>
</template>

<script>
import statsActions from "@/calendesk/mixins/statsActions";
import NoDataInfo from "@/components/common/NoDataInfo.vue";
import sharedList from "@/calendesk/mixins/sharedList";
import UserSubscriptionsFilter from "@/views/dashboard/pages/Sales/UserSubscriptions/components/UserSubscriptionsFilter.vue";
import UserSubscriptionRow from "@/views/dashboard/pages/Sales/UserSubscriptions/components/UserSubscriptionRow.vue";
import userSubscriptionActions from "@/calendesk/mixins/userSubscriptionActions";
import TableFooterMenu from "@/components/TableFooterMenu.vue";

export default {
  name: "UserSubscriptionList",
  components: {
    TableFooterMenu,
    UserSubscriptionRow,
    UserSubscriptionsFilter,
    NoDataInfo,
  },
  mixins: [statsActions, sharedList, userSubscriptionActions],
  data() {
    return {
      cacheId: "user-subscription-list",
      requestActionName: "fetchUserSubscriptions",
      statsMode: this.$helpers.statsMode.userSubscriptions,
      isRemovingUserSubscriptions: false,
    };
  },
  computed: {
    headers() {
      const headers = [
        {
          text: this.$trans("id"),
          align: "start",
          value: "id",
          class: "header-style",
          sortable: true,
        },
        {
          text: this.$trans("created_at"),
          value: "created_at",
          class: "header-style",
        },
        {
          text: this.$trans("canceled_at"),
          value: "canceled_at",
          class: "header-style",
        },
        {
          text: this.$trans("subscription"),
          value: "subscription",
          sortable: false,
          class: "header-style",
        },
        {
          text: this.$trans("client"),
          value: "user",
          sortable: false,
          class: "header-style",
        },
        {
          text: this.$trans("status"),
          value: "status",
          sortable: false,
          class: "header-style",
          align: "center",
        },
        {
          text: this.$trans("usage"),
          value: "usage",
          sortable: false,
          class: "header-style",
          align: "center",
        },
      ];

      if (
        this.hasPermission(this.employeePermissions.EDIT_USER_SUBSCRIPTIONS)
      ) {
        headers.push({
          text: this.$trans("manage"),
          value: "menu",
          class: "header-style",
          sortable: false,
          align: "center",
        });
      }

      return headers;
    },
    isLoadingInSelectMenu() {
      return this.isRemovingUserSubscriptions;
    },
    onSelectActions() {
      return [
        {
          title: {
            single: "send_customer_notification_title",
            many: "send_customers_notification_title",
          },
          action: this.sendCustomerNotification,
          icon: "$send",
        },
        {
          title: {
            single: "cancel_user_subscriptions",
            many: "cancel_user_subscriptions",
          },
          action: this.handleCancelUserSubscriptions,
          icon: "$close",
          color: "error",
          class: "error--text",
        },
        {
          title: {
            single: "delete_user_subscriptions",
            many: "delete_user_subscriptions",
          },
          action: this.handleDeleteUserSubscriptions,
          icon: "$trash-default",
          color: "error",
          class: "error--text",
        },
      ];
    },
  },
  watch: {
    getRefreshUserSubscriptionsListStatus() {
      this.forceReload();
    },
  },
  created() {
    this.initialLoading();
  },
  methods: {
    initialLoading() {
      const requests = [];
      if (!this.searchedSubscriptions) {
        requests.push(
          this.searchSubscriptions({ order_by: "id", ascending: 0 })
        );
      }

      if (requests && requests.length > 0) {
        Promise.all(requests).finally(() => {});
      }
    },
  },
};
</script>
