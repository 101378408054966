<template>
  <v-autocomplete
    v-model="selectedSubscriptions"
    :items="searchedSubscriptions || []"
    :label="$trans('subscription')"
    :dense="dense"
    :multiple="multiple"
    :chips="chips"
    :small-chips="smallChips"
    :item-text="getSubscriptionName"
    item-value="id"
    outlined
    :height="getHeight"
    :disabled="disabled"
    hide-details="auto"
    clearable
    :deletable-chips="deletableChips"
    @change="$emit('input', selectedSubscriptions)"
  >
    <template #no-data>
      <div class="v-subheader theme--light">
        {{ $trans("nothing_found_here") }}
      </div>
    </template>

    <template #selection="data">
      <div class="d-flex align-center pointer" style="max-width: 99%">
        <template v-if="chips || smallChips">
          <v-chip
            :small="smallChips"
            :disabled="disabled"
            :close="deletableChips"
            @click:close="removeChip(data.item)"
          >
            <span class="text-truncate">{{
              getSubscriptionName(data.item)
            }}</span>
          </v-chip>
        </template>
        <template v-else>
          <span class="text-truncate">{{
            getSubscriptionName(data.item)
          }}</span>
        </template>
      </div>
    </template>

    <template #item="data">
      <div class="d-flex align-center pointer my-2">
        <span class="text-break cd-v-select-line-height">{{
          getSubscriptionName(data.item)
        }}</span>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters } from "vuex";
import { getSubscriptionName } from "@/lib/calendesk-js-library/tools/helpers";

export default {
  name: "SubscriptionField",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    dense: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "auto",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    smallChips: {
      type: Boolean,
      default: false,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedSubscriptions: this.value,
    };
  },
  computed: {
    ...mapGetters({
      searchedSubscriptions: "subscriptions/getSearchedSubscriptions",
    }),
    getHeight() {
      if (
        this.selectedSubscriptions === null ||
        this.selectedSubscriptions === undefined ||
        this.selectedSubscriptions.length === 0
      ) {
        return 0;
      }

      return null;
    },
  },
  watch: {
    value(val) {
      if (!val || val.length === 0) {
        this.selectedSubscriptions = [];
      }
    },
  },
  methods: {
    getSubscriptionName,
    removeChip(item) {
      if (this.multiple) {
        const index = this.selectedSubscriptions.findIndex(
          (service) => service.id === item.id
        );
        if (index > -1) {
          this.selectedSubscriptions.splice(index, 1);
        }
      } else {
        this.selectedSubscriptions = null;
      }

      this.$emit("input", this.selectedSubscriptions);
    },
  },
};
</script>
