<template>
  <div>
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-icon color="light" class="mr-2" v-bind="attrs" v-on="on"
          >$form-fields</v-icon
        >
      </template>

      <div v-for="(field, index) in gdprFields" :key="index">
        {{ field.name }}
      </div>
    </v-tooltip>
    <span>{{ $trans("wb_gdpr_fields") }}</span>
  </div>
</template>
<script>
export default {
  props: {
    gdprFields: {
      type: Array,
      required: true,
    },
  },
};
</script>
