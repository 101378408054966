var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isSending)?_c('div',{staticClass:"list mb-16"},[(_vm.userSubscriptionsExist)?_c('user-subscriptions-filter',{on:{"filtersChanged":_vm.filtersChanged,"filtersRestored":_vm.filtersRestored}}):(!_vm.userSubscriptionsExist)?_c('no-data-info',{attrs:{"description":_vm.$trans('no_user_subscriptions_info'),"button-text":_vm.$trans('create_user_subscription_button_title')},on:{"click":_vm.openAddUserSubscription}}):_vm._e(),(_vm.userSubscriptionsExist)?_c('v-data-table',{staticClass:"calendesk-datatable",attrs:{"footer-props":_vm.footerProps,"headers":_vm.headers,"items":_vm.items,"loading":_vm.isLoadingTable,"loading-text":_vm.$trans('loading'),"no-data-text":_vm.$trans('nothing_found_here'),"no-results-text":_vm.$trans('nothing_found_here'),"options":_vm.tableOptions,"server-items-length":_vm.total,"show-select":!_vm.noData,"mobile-breakpoint":"960"},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('v-simple-checkbox',{attrs:{"ripple":false,"indeterminate":_vm.isIndeterminateForSelectAll,"color":"primary"},on:{"input":_vm.selectAllItemsOnChange},model:{value:(_vm.selectAllState),callback:function ($$v) {_vm.selectAllState=$$v},expression:"selectAllState"}})],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('user-subscription-row',{attrs:{"item":item,"is-selected":_vm.isRowSelected({
            id: item.id,
          })},on:{"selectOnChange":_vm.itemRowEventOnSelectChange}})]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e(),_c('table-footer-menu',{attrs:{"on-select-actions":_vm.onSelectActions,"selected":_vm.selected,"is-loading-in-select-menu":_vm.isLoadingInSelectMenu,"selection-map":function (rowItem) {
        return {
          id: rowItem.item.id,
          status: rowItem.item.status,
        };
      }}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }