<template>
  <list-buttons
    :is-hovered="isHovered"
    :extra-actions="extraActions"
    :primary-actions="primaryActions"
    @fireAction="fire"
  />
</template>
<script>
import ListButtons from "@/components/ListButtons.vue";
import userSubscriptionActions from "@/calendesk/mixins/userSubscriptionActions";

export default {
  name: "UserSubscriptionListButtons",
  components: { ListButtons },
  mixins: [userSubscriptionActions],
  props: {
    userSubscription: {
      type: Object,
      required: true,
    },
    isHovered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    extraActions() {
      const actions = [];

      actions.push({
        action: "pay",
        color: "light",
        icon: "$cash",
        title: this.$trans("user_payments"),
      });

      if (
        this.userSubscription.user &&
        this.canSendNotificationsToUser(this.userSubscription.user)
      ) {
        actions.push({
          action: "send_notification_to_user",
          color: "light",
          icon: "$send",
          title: this.$trans("send_customer_notification_title"),
        });
      }

      if (this.canCancelUserSubscription(this.userSubscription)) {
        actions.push({
          action: "cancel",
          color: "error",
          icon: "$close",
          title: this.$trans("cancel"),
        });
      }

      if (this.canDeleteUserSubscription(this.userSubscription)) {
        actions.push({
          action: "delete",
          color: "error",
          icon: "$trash-default",
          title: this.$trans("delete"),
        });
      }

      return actions;
    },
    primaryActions() {
      const actions = [
        {
          action: "pay",
          color: "light",
          icon: "$cash",
          title: this.$trans("user_payments"),
        },
      ];

      if (
        this.userSubscription.user &&
        this.canSendNotificationsToUser(this.userSubscription.user)
      ) {
        actions.push({
          action: "send_notification_to_user",
          color: "light",
          icon: "$send",
          title: this.$trans("send_customer_notification_title"),
        });
      }

      return actions;
    },
  },
  methods: {
    fire(action) {
      const cloned = this.$helpers.cloneObject(this.userSubscription);
      switch (action) {
        case "pay":
          this.openPaymentsDialog(cloned);
          break;
        case "send_notification_to_user":
          this.sendCustomerNotification([cloned]);
          break;
        case "delete":
          this.handleDeleteUserSubscriptions([cloned]);
          break;
        case "cancel":
          this.handleCancelUserSubscriptions([cloned]);
          break;
      }
    },
  },
};
</script>
