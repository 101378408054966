<template>
  <v-container class="table-filters" fluid>
    <v-row>
      <v-col cols="12" sm="3">
        <calendar-range-input
          v-model="selectedFiltersCreatedDateRange"
          only-past-dates
          dense
          :label="$trans('created_date_range')"
          clearable
          :disabled="disabled"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <calendar-range-input
          v-model="selectedFiltersCanceledDateRange"
          only-past-dates
          dense
          :label="$trans('canceled_date_range')"
          clearable
          :disabled="disabled"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <calendar-range-input
          v-model="selectedFiltersEndsDateRange"
          dense
          :label="$trans('date_of_expiry')"
          clearable
          :disabled="disabled"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3">
        <v-select
          v-model="selectedFiltersStatus"
          :items="availableSubscriptionStatusFilters"
          :label="$trans('status')"
          outlined
          clearable
          hide-details
          :disabled="disabled"
          dense
          small-chips
          deletable-chips
          :menu-props="{ offsetY: true }"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <clients-field
          v-model="selectedFiltersUsers"
          hide-actions
          dense
          small-chips
          multiple
          deletable-chips
          :disabled="disabled"
          keep-menu-open-on-select
        />
      </v-col>
      <v-col cols="12" sm="3">
        <subscription-field
          v-model="selectedFiltersSubscriptionIds"
          :disabled="disabled"
          small-chips
          deletable-chips
          dense
          multiple
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          v-model="selectedFiltersSearch"
          :label="$trans('search')"
          :placeholder="$trans('autocomplete_hint')"
          append-icon="$magnify"
          hide-details
          outlined
          single-line
          clearable
          :disabled="disabled"
          dense
        />
      </v-col>
    </v-row>
    <v-row class="my-4" justify="center">
      <v-col class="d-flex align-center justify-center" cols="12" md="3" sm="6">
        <v-btn
          color="primary"
          outlined
          small
          :disabled="disabled"
          @click="clearFilters"
        >
          {{ $trans("clear_filters") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import sharedListFilters from "@/calendesk/mixins/sharedListFilters";
import { trans } from "@/lib/calendesk-js-library/prototypes/trans";
import userSubscriptionActions from "@/calendesk/mixins/userSubscriptionActions";
import ClientsField from "@/views/dashboard/pages/Calendar/components/forms/Booking/ClientsField.vue";
import SubscriptionField from "@/components/Forms/SubscriptionField.vue";
import CalendarRangeInput from "@/lib/calendesk-js-library/components/CalendarRangeInput.vue";

export default {
  name: "UserSubscriptionsFilter",
  components: {
    CalendarRangeInput,
    SubscriptionField,
    ClientsField,
  },
  mixins: [sharedListFilters, userSubscriptionActions],
  data() {
    return {
      cacheId: "user-subscriptions-list-filters",
      selectedFiltersCreatedDateRange: [],
      selectedFiltersCanceledDateRange: [],
      selectedFiltersEndsDateRange: [],
      selectedFiltersStatus: null,
      selectedFiltersUsers: null,
      selectedFiltersSubscriptionIds: null,
      selectedFiltersSearch: null,
      availableSubscriptionStatusFilters: [
        {
          text: trans("active"),
          value: "active",
        },
        {
          text: trans("initiated"),
          value: "initiated",
        },
        {
          text: trans("canceled"),
          value: "canceled",
        },
      ],
    };
  },
  computed: {
    filters() {
      return {
        selectedFiltersCreatedDateRange: this.selectedFiltersCreatedDateRange,
        selectedFiltersCanceledDateRange: this.selectedFiltersCanceledDateRange,
        selectedFiltersEndsDateRange: this.selectedFiltersEndsDateRange,
        selectedFiltersStatus: this.selectedFiltersStatus,
        selectedFiltersUsers: this.selectedFiltersUsers,
        selectedFiltersSubscriptionIds: this.selectedFiltersSubscriptionIds,
        selectedFiltersSearch: this.selectedFiltersSearch,
      };
    },
  },
  methods: {
    getData() {
      const data = {};

      if (
        this.selectedFiltersCreatedDateRange &&
        this.selectedFiltersCreatedDateRange.length > 0
      ) {
        data.created_from = this.selectedFiltersCreatedDateRange[0];

        if (this.selectedFiltersCreatedDateRange.length === 2) {
          data.created_to = this.selectedFiltersCreatedDateRange[1];
        } else {
          data.created_to = data.created_from;
        }
      }

      if (
        this.selectedFiltersCanceledDateRange &&
        this.selectedFiltersCanceledDateRange.length > 0
      ) {
        data.canceled_from = this.selectedFiltersCanceledDateRange[0];

        if (this.selectedFiltersCanceledDateRange.length === 2) {
          data.canceled_to = this.selectedFiltersCanceledDateRange[1];
        } else {
          data.canceled_to = data.canceled_from;
        }
      }

      if (
        this.selectedFiltersEndsDateRange &&
        this.selectedFiltersEndsDateRange.length > 0
      ) {
        data.ends_from = this.selectedFiltersEndsDateRange[0];

        if (this.selectedFiltersEndsDateRange.length === 2) {
          data.ends_to = this.selectedFiltersEndsDateRange[1];
        } else {
          data.ends_to = data.ends_from;
        }
      }

      if (this.selectedFiltersStatus) {
        data.status = this.selectedFiltersStatus;
      }

      if (this.selectedFiltersUsers && this.selectedFiltersUsers.length > 0) {
        data.user_ids = this.selectedFiltersUsers.map((u) => u.id).join(",");
      }

      if (
        this.selectedFiltersSubscriptionIds &&
        this.selectedFiltersSubscriptionIds.length > 0
      ) {
        data.subscription_ids = this.selectedFiltersSubscriptionIds.join(",");
      }

      if (this.selectedFiltersSearch) {
        data.query = this.selectedFiltersSearch;
      }

      return data;
    },
  },
};
</script>
