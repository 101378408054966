var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{key:_vm.item.id,staticClass:"table-row",class:{
    'v-data-table__mobile-table-row': !_vm.$vuetify.breakpoint.mdAndUp,
  },on:{"click":function($event){$event.stopPropagation();return _vm.clickRow(_vm.item)},"mouseover":function($event){_vm.isHovered = true},"mouseleave":function($event){_vm.isHovered = false}}},[_c('td',{staticClass:"pt-2 text-center",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__checkbox-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"}):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('v-simple-checkbox',{attrs:{"ripple":false,"value":_vm.isSelected,"color":"primary"},on:{"input":function($event){return _vm.selectOnChange($event)}}})],1)]),_c('td',{staticClass:"pt-2 text-center",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__5-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_c('div',[_vm._v(" "+_vm._s(_vm.$trans("id"))+" ")])]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('div',{staticClass:"text-left"},[_c('div',[_vm._v(_vm._s(_vm.item.id))])])])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__15-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("created_at"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('span',[_vm._v(_vm._s(_vm._f("dateTime")(_vm.item.created_at,_vm.$helpers.hourDayMonthAndYearDate)))])])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__15-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("canceled_at"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[(_vm.item.canceled_at)?_c('span',[_vm._v(_vm._s(_vm._f("dateTime")(_vm.item.canceled_at,_vm.$helpers.hourDayMonthAndYearDate)))]):_vm._e()])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__25-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("subscription"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[(_vm.item.subscription)?_c('div',[_c('span',[_vm._v("(ID: "+_vm._s(_vm.item.subscription.id)+") "+_vm._s(_vm.item.subscription.name))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.item.stripe_subscription_id))])]):_vm._e()])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__20-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("client"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('user-card-details',{attrs:{"dense":"","details-in-tooltip":"","justify-left":"","clickable":"","push-transition":"","user":_vm.item.user}}),(_vm.item.gdpr_fields)?_c('gdpr-fields-tooltip',{staticClass:"mt-2",attrs:{"gdpr-fields":_vm.item.gdpr_fields}}):_vm._e()],1)])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__5-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("status"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('div',{staticClass:"text-center"},[_c('v-chip',{staticClass:"ma-2",class:_vm.$helpers.getStatusColor(_vm.item.status).class,attrs:{"small":"","color":_vm.$helpers.getStatusColor(_vm.item.status).color}},[_vm._v(" "+_vm._s(_vm.$trans(_vm.item.status))+" ")]),(_vm.item.ends_at)?_c('small',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.$trans("ends_at"))+": "+_vm._s(_vm._f("dateTime")(_vm.item.ends_at,_vm.$helpers.hourDayMonthAndYearDate))+" ")]):_vm._e(),(_vm.item.status === 'canceled' && !_vm.item.ends_at)?_c('small',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.$trans("ends_at_default"))+" ")]):_vm._e()],1)])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__5-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("usage"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('div',{staticClass:"text-center"},[(_vm.canShowUsage(_vm.item))?_c('v-chip',{staticClass:"green--text ma-2",attrs:{"text":"","small":"","color":"green-light"}},[_c('span',[_vm._v(_vm._s(_vm.getUsage(_vm.item)))])]):_vm._e()],1)])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__manage-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[_c('div',{staticClass:"row-list-buttons white rounded mr-1 mt-2"},[_c('user-subscription-list-buttons',{attrs:{"is-hovered":_vm.isHovered,"user-subscription":_vm.item}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }